import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Form,
  Header,
  Icon,
  Input,
  Menu,
  Modal,
  Popup,
} from "semantic-ui-react";
import { AdminsDropdown, StatusDropdown } from "../../../components";
import { ClientAction } from "../../../redux/actions";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

const Info = () => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [transferModal, setTransferModal] = useState();
  const [transferBroker, setTransferBroker] = useState();
  const [editablePhone, setEditablePhone] = useState();
  const [isNewPhone, setIsNewPhone] = useState(false);

  const { id } = useParams();

  const user = useSelector((state) => state.user).data;

  const archive = async (archived) => {
    const response = await ClientAction.archive({ id, archived });
    if (response.ok) {
      getClient();
    }
  };

  const transferClient = async () => {
    const response = await ClientAction.transfer({
      id,
      broker: transferBroker,
    });
    if (response.ok) {
      getClient();
    }
  };

  const getClient = async () => {
    const response = await ClientAction.get(id);
    if (response.ok) {
      const data = await response.json();
      setForm({ ...data, date_in_archive: new Date(data.date_in_archive) });
    }
  };

  useEffect(() => {
    getClient();
  }, [id]);

  const submit = async () => {
    const data = { ...form };
    delete data.phones;
    const response = await ClientAction.patchUpdate(data);
    if (response.ok) {
      getClient();
    } else {
      setErrors(await response.json());
    }
  };

  const deletePhone = async (idx) => {
    setErrors({});
    const response = await ClientAction.deletePhone({ id: form.id, idx });
    if (response.ok) {
      setEditablePhone(false);
      getClient();
    } else {
      setErrors(await response.json());
    }
  };

  const savePhone = async (idx) => {
    setErrors({});
    const response = await ClientAction.updatePhone({
      id: form.id,
      phone: form.phones[idx],
      idx,
    });
    if (response.ok) {
      setEditablePhone(false);
      getClient();
    } else {
      const updateErrors = await response.json();
      toast.error(updateErrors.phone);
      setErrors(updateErrors);
    }
  };

  return (
    <>
      <Form>
        <Form.Field>
          <Button
            floated="right"
            type="button"
            content={form.archived ? "Разархивировать" : "Архивировать"}
            icon="trash"
            color={form.archived ? "yellow" : "red"}
            onClick={() => archive(!form.archived)}
          />
          <Button
            floated="right"
            type="button"
            content={"Передать клиента"}
            icon="trash"
            onClick={() => setTransferModal({})}
          />
        </Form.Field>
        <Form.Input
          label="ФИО"
          placeholder="Введите фио клиента"
          value={form.name}
          error={errors.name}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
        />
        <Form.Input
          label="Комментарий"
          placeholder="Введите комментарий"
          value={form.comment}
          onChange={(e) => setForm({ ...form, comment: e.target.value })}
        />
        <Form.Field>
          <label>Статус</label>
          <StatusDropdown
            value={form.status}
            onChange={(status) => setForm({ ...form, status })}
          />
        </Form.Field>
        <Header dividing>
          <Button
            floated="right"
            content="Добавить"
            onClick={() => {
              setEditablePhone(form.phones.length);
              setForm({ ...form, phones: [...form.phones, ""] });
              setIsNewPhone(true);
            }}
            icon="add"
          />

          <Header.Content>Телефоны</Header.Content>
          <Header.Subheader>Список телефонов клиента</Header.Subheader>
        </Header>
        {(form.phones || []).map((phone, index) => (
          <Form.Field error={index === editablePhone && errors.phone}>
            <label>Телефон {index}</label>
            <Input
              key={index}
              disabled={index !== editablePhone}
              onChange={(e) =>
                setForm({
                  ...form,
                  phones: form.phones.map((p, i) =>
                    i === index ? e.target.value : p
                  ),
                })
              }
              label={
                <>
                  <Button
                    icon
                    onClick={
                      index === editablePhone
                        ? () => savePhone(index)
                        : () => setEditablePhone(index)
                    }
                  >
                    <Icon name={index === editablePhone ? "check" : "edit"} />
                  </Button>
                  {index === editablePhone && (
                    <>
                      <Button
                        basic
                        icon
                        onClick={() => {
                          if (isNewPhone) {
                            setIsNewPhone(false);
                            setForm({
                              ...form,
                              phones: form.phones.filter((p, i) => i !== index),
                            });
                          }
                          setEditablePhone();
                        }}
                      >
                        <Icon name={"close"} />
                      </Button>
                      {!isNewPhone && (
                        <Popup
                          content={
                            <Button.Group>
                              <Button
                                content="Удалить"
                                onClick={() => deletePhone(index)}
                              />
                            </Button.Group>
                          }
                          on="click"
                          pinned
                          trigger={
                            <Button color="red" basic icon>
                              <Icon name="trash" />
                            </Button>
                          }
                        />
                      )}
                    </>
                  )}
                </>
              }
              labelPosition="right"
              placeholder="Введите телефон"
              value={phone}
            />
          </Form.Field>
        ))}
        {user.role === "admin" && form.date_in_archive && (
          <Form.Field>
            <label>Дата архивации</label>
            <DatePicker
              isClearable
              dateFormat={"dd.MM.yyyy"}
              timeFormat="HH:mm:ss"
              selected={form.date_in_archive}
              onChange={(date) =>
                setForm({
                  ...form,
                  date_in_archive: date,
                })
              }
            />
          </Form.Field>
        )}
        <Form.Field>
          <Button
            icon="save"
            content="Сохранить"
            color="green"
            onClick={submit}
          />
        </Form.Field>
      </Form>
      <Modal open={!!transferModal} onClose={() => setTransferModal()}>
        <Modal.Header>Передать клиента</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Брокер</label>
              <AdminsDropdown
                role={"all"}
                onChange={(broker) => setTransferBroker(broker)}
                value={transferBroker}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Отменить" onClick={() => setTransferModal()} />
          <Button content="Передать" color="green" onClick={transferClient} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default Info;
